.detailBook h2 {
  color: #4E5360;
  font-size: 18px;
  padding: 0 0 20px 0;
}

.detailBook h2 span {
  color: #FF9710;
  font-size: 18px;
}

.text-center {
  text-align: center;
}

.titleYear {
  color: #1F3E72;
  font-size: 16px;
  padding: 20px 0;
}

.titleYear span {
  color: #FF9710;
  font-size: 16px;
}

.titleFacilities {
  color: #1F3E72;
  font-size: 20px;
  padding: 10px 0 0 0;
  background-color: #f7f6f6;
  font-weight: 600;
}

.facilitiesList h6 {
  color: #1F3E72;
  font-size: 14px;
}

.facilitiesList span {
  font-weight: 500;
}

.freeOpd {
  color: #FF9710;
}

.book_rightSec {
  width: 50%;
  background-color: #FFF5D5;
  margin: 0 10px 0 0;
  height: 180px;
  position: relative;
}

.book_rightSec img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.hospitalDetailPage .box {
  padding: 20px;
  cursor: pointer;
  display: flex;
}

.hospitalDetailPage .content {
  padding: 10px;
}

.hospitalDetailPage .react-multiple-carousel__arrow {
  display: none;
}

.slideText {
  width: 50%;
}

.slideText h4 {
  color: #07003B;
  font-size: 18px;
}

.slideText h5 {
  color: #4E5360;
  font-size: 16px;
}

.slideText h6 {
  color: #4E5360;
  font-size: 14px;
}

.bookingfooter {
  background-color: #FFD016;
  padding: 5px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.bookingfooter p {
  color: #fff;
}

.pr {
  padding-right: 10px;
  color: #1F3E72;
  font-weight: 600;
  width: 100px;
}

.changeChildModal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 16px;
  padding: 40px 24px 24px 24px;
}

@media screen and (max-width: 767px) {
  .bookingfooter p {
    color: #fff;
  }
}

.sample {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  /* margin-bottom: 20px; */
}