.featured .mainTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featured .mainTitle span {
  color: #FF9710;
  font-weight: 500;
}

.featured .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.featured .mainTitle h2 {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  margin: 5px 0 0 0;
}

.featured .box {
  box-shadow: 0 4px 4px rgb(153 153 153 / 39%);
  border-radius: 40px 10px;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  background-color: #f7f8f9;
  height: 100%;
}

.featured .box :after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url(../../../../public/images/pattern-3.png) no-repeat;
}

.featured .box:hover {
  box-shadow: none;
}

.featured img {
  width: 65px;
  height: 65px;
  margin: auto;
}

.featured .content {
  padding: 10px;
}

.featured .content h4 {
  margin: 0;
  padding: 10px 0 0 0;
  color: #4E5360;
  font-size: 13px;
  line-height: 1.35;
}

.featured .react-multi-carousel-list {
  padding: 30px 0 0 0;
}

.featured button {
  top: 0;
  background: #009EE0;
}

.featured .react-multiple-carousel__arrow--left {
  left: calc(90% + 1px);
}

.react-multiple-carousel__arrow--right {
  right: 0px;
}

.featured {
  position: relative;
}

.icon_1 {
  position: absolute;
  right: 0px;
  top: 10px;
}

@media only screen and (max-width: 1280px) and (min-width: 998px) {
  .featured .content h4 {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 767px) {
  .featured .react-multiple-carousel__arrow--left {
    left: calc(60% + 1px) !important;
  }

  .featured .mainTitle {
    flex-wrap: wrap;
  }

  .featured .content h4,
  .featured .mainTitle span {
    font-size: 10px;
  }

  .featured .mainTitle h2 {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }

  .featured .react-multi-carousel-list button {
    top: 15px;
  }
}