.formBg {
  display: block;
  padding: 4em 0;
  /* background: #fff url("../../../public/images/pattern-fworks-light.svg") no-repeat; */
  background-position: bottom;
}

.text-center {
  text-align: center;
}

.checkBoxSign {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 10px 0 0 0px !important;
}

.register_page .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #000 !important;
}

.register_page .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: rgb(255, 152, 0) !important;
  border-radius: 0 !important;
  background: #ffffff !important;
}

.register_page .css-1aquho2-MuiTabs-indicator {
  background-color: rgb(255, 152, 0) !important;
}

.register_page .css-19kzrtu {
  padding: 0px !important;
}

.sign_form {
  padding: 10px 0;
  text-align: center;
}

.sign_form .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  margin: 10px 0;
}

.signBtn {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 10px 20px !important;
  margin: 20px 0 0 0px !important;
  color: #fff !important;
}

.signIn {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 5px 20px !important;
  margin: 20px 0 0 0 !important;
  color: #fff !important;
  width: 70%;
}

.signIn a {
  color: #fff !important;
  font-size: 13px;
  font-weight: 500;
}

.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root,
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 5px !important;
}

.signInleftSection {
  background: #E8F1FF;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 10%);
  padding: 3em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.needHelpSty .title {
  font-size: 38px;
  line-height: 48px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #07003B;
}

.needHelpSty span {
  font-weight: 500;
}

.needHelpSty p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.maintitle {
  font-size: 30px;
  margin-top: 0px;
  padding: 0px;
  margin-bottom: 0px;
  line-height: 35px;
  color: #FF9710;
  font-weight: 500;
}

.b-x {
  box-shadow: 0px 0px 50px #0000000A;
  border-radius: 10px;
  overflow: hidden;
}

.needHelpSty p {
  color: #4E5360;
}

.bg_1 {
  position: absolute;
  top: 0;
  width: 120px;
  height: 120px;
  right: 40px;
}

.bg_2 {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 100px;
  left: 40px;
}

.bg_3 {
  position: absolute;
  bottom: -20px;
  width: 120px;
  height: 120px;
  right: -20px;
}

.changeModal {
  width: 30% !important;
}

@media screen and (max-width: 767px) {
  .signInn .back span {
    display: none !important;
  }

  .needHelpSty .title {
    font-size: 25px !important;
  }
}