.cliniCard {
  box-shadow: 0px 0px 80px #0000000D;
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  margin: 15px 2px;
}

.cliniCardImg {
  width: 300px;
  height: 250px;
  margin: 0 auto;
  border-radius: 10px 10px 0 0;
}

.cliniCardImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cliniCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cliniCardTitle h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1F3E72;
  margin: 0;
}

.cliniCardTitle h3 span {
  color: #FF9710;
}

.cliniCardContent p {
  font-size: 14px;
  font-weight: 400;
  color: #707E95;
  margin: 0;
}

.clinicD {
  padding: 15px 0;
}

.clinicDItem {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.clinicDItem p {
  font-size: 14px;
  font-weight: 400;
  color: #1F3E72;
  margin: 0;
}

.clinicDicon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clinicDicon svg {
  width: 18px;
  color: #09CF65;
}

.viewBook {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .ayurveda .viewBook {
    flex-wrap: wrap !important;
  }

  .clinicDItem p {
    width: 90% !important;
  }

  .cliniCardTitle h3,
  .contactL {
    font-size: 12px !important;
  }

  .viewBook a {
    font-size: 12px;
  }
}