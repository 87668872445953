.editProfileTitle h3 {
  color: #1F3E72;
  font-size: 30px;
  margin: 0 0 30px 0;
}

.editProfileTitle a {
  border: 1px solid #FF9710;
  color: #FF9710;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 50px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.profileImg {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
}

.cameraImg {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 30px !important;
  height: 30px !important;
}

.signBtn {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 10px 20px !important;
  margin: 20px 0 0 0px !important;
  color: #fff !important;
}

.signBtn:hover {
  background-color: #fff !important;
  color: #009EE0 !important;
}

.deleteProfile{
  box-shadow: 0 0 20px 0 rgb(70 70 70 / 18%);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 30px;
}

.deleteProfile h3{
  font-size: 30px;
  font-weight: 500;
  color: #4E5360;
}

.deleteProfile .signBtn{
  width: 50%;
  font-size: 14px;
}

.profileIm {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 20px auto 20px auto;
}

.profileIm img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .editProfile .css-1c033at {
    padding: 10px !important;
  }
}