.aboutSection .mainTitle span {
  color: #FF9710;
  font-weight: 500;
}

.aboutSection .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.aboutSection .mainTitle h2 {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  margin: 5px 0 20px 0;
}

.aboutSection_span {
  color: #FF9710;
  font-size: 18px;
}

.aboutSection_title {
  color: #07003B;
  font-weight: 600;
}

.aboutSection_p {
  color: #4E5360;
}

.aboutSection_img {
  width: 70%;
  margin: 0 auto;
}

.aboutSection_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.aboutSection {
  position: relative;
  overflow: hidden;
}

.icon_2 {
  position: absolute;
  right: -30px;
  width: 100px;
  height: 100px;
  top: -30px;
}

@media screen and (max-width: 767px) {
  .aboutSection .mainTitle span {
    font-size: 12px;
  }

  .aboutSection .mainTitle h2 {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }
}