.shortingBtn {
  display: flex;
  align-items: center;
  justify-content: end;
}

.shortingBtn button {
  margin: 0 5px;
  font-weight: 500;
  border-radius: 5px !important;
}

.bookingHistoryList {
  border: 1px solid #f4f4f4;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 90px #0000000a;
}

.bookingHistoryImg {
  border-right: 1px solid #ddd;
  padding: 0 10px 0 0;
  height: 160px;
  width: 160px;
}

.bookingHistoryImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bookingList h3 {
  color: #07003B;
  font-size: 18px;
}

.generalLabList {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.icon_sec {
  background-color: #FFE6EB;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_sec svg {
  color: #ddd;
  color: #b3aeae;
  width: 18px;
}

.generalLabList p {
  color: #707E95;
  padding: 0 0 0 10px;
  font-size: 16px !important;
}

.modalBooking .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(0 0 0 / 2%);
}

.modalBookingBox {
  border-radius: 10px;
  box-shadow: none !important;
}

.mainTitle {
  font-size: 20px !important;
}

.titleTwo {
  font-size: 18px !important;
}

.titleThree {
  font-size: 16px !important;
}

@media screen and (max-width: 767px) {
  .bookingHistoryImg {
    border-right: none;
    border-bottom: 1px solid #ddd;
    width: 100%;
    margin: 0 0 10px 0;
  }

  .shortingBtn {
    margin: 15px 0 0 0;
  }
}