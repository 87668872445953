.home,
.home .react-multi-carousel-item,
.home .react-multi-carousel-track,
.home .react-multi-carousel-track img {
  height: 600px;
}

.home {
  padding: 70px 0 0 0;
  overflow: hidden;
}

.home .box {
  display: flex;
  align-items: center;
}

.img_banner {
  width: 50%;
  height: 450px;
  border: none;
  overflow: hidden;
}

.img_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
}

.bannerContent {
  width: 50%;
}

.bannerContent h1 {
  font-size: 50px;
  font-weight: 700;
}

.bannerContent span {
  color: #FF9710;
}

.home {
  background-color: #E8F1FF;
}

.home button {
  bottom: 80px;
  background: #009EE0;
}

.home .react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
  z-index: 0;
}

.home .react-multiple-carousel__arrow--right {
  right: calc(90% + 1px);
  z-index: 0;
}

.zoom-in-out-box {
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  top: 100px;
  background: #ff971070;
  animation: zoom-in-zoom-out 3s ease infinite;
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.zoom-in-out-box2 {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  border-radius: 50%;
  bottom: 90px;
  background: #FF9710;
  animation: zoom-in-zoom-out 3s ease infinite;
}

@media screen and (max-width: 767px) {
  .home {
    overflow: hidden;
  }

  .bannerContent {
    padding: 0 10px 0 0;
  }

  .img_banner {
    width: 50%;
    height: 200px;
    border: none;
    overflow: hidden;
  }

  .img_banner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .home .react-multiple-carousel__arrow--right {
    right: calc(60% + 1px) !important;
  }

  .home button {
    bottom: 0px !important;
  }

  .react-multiple-carousel__arrow {
    z-index: 0 !important;
  }

  .home {
    height: 480px !important;
    padding: 130px 0 0 0;
  }

  .home .react-multi-carousel-list,
  .home .react-multi-carousel-track,
  .home .react-multi-carousel-item {
    height: 300px !important;
  }

  .zoom-in-out-box2 {
    bottom: 0px;
  }
}