.joinNow {
  background-color: #fff;
  border-radius: 10px;
  margin: -130px 0 0 0;
  padding: 40px 50px 50px 50px;
  box-shadow: 0 0 20px 0 rgb(70 70 70 / 18%);
}

.jointitle {
  font-size: 25px;
  font-weight: 600;
  color: #07003B;
  margin: 0px;
}

@media screen and (max-width: 767px) {
  .joinNow {
    padding: 15px 20px 10px 20px;
  }

  .jointitle {
    font-size: 20px !important;
  }

  .w-25 {
    width: 100% !important;
    margin: 0px !important;
  }

  .css-hyk0ov-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }
}