.showAll {
  color: #4E5360;
  font-size: 20px;
  position: relative;
  padding: 10px 0 10px 0;
}

.showAll::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 150px;
  height: 2px;
  background-color: #FF9710;
}

.css-35zqo5-MuiTabs-root {
  border: none !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #009EE0 !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.css-10d9dml-MuiTabs-indicator {
  display: none;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background-color: #E6E6E6 !important;
  border-radius: 0px !important;
  color: #4E5360 !important;
  margin: 10px 0 !important;
  text-align: left !important;
  display: flex !important;
  align-items: left !important;
  -webkit-align-items: left !important;
}

.tabSection {
  width: 30% !important;
}

.tabPannel {
  width: 70% !important;
}

.css-19kzrtu {
  padding: 0px 24px !important;
}

.categoryListt {
  width: 50%;
  padding: 0 10px 20px 10px;
  margin: 0px !important;
}

.categoryListt h3 {
  color: #4E5360;
  font-size: 14px;
}

.categoryListt p {
  color: #7F8084;
  font-size: 12px;
}

.bookNowtext {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 10px 40px !important;
  color: #fff !important;
  transition: all 0.3s ease !important;
}

.titleModal {
  color: #4E5360 !important;
  font-size: 20px !important;
  padding: 0 0 10px 0 !important;
}

.titleModal span {
  color: #FF9710 !important;
}

.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.yesBtn {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 5px !important;
  padding: 5px 30px !important;
  color: #fff !important;
  transition: all 0.3s ease !important;
}

.noBtn {
  background-color: #F5F5F5 !important;
  border: 1px solid #F5F5F5 !important;
  border-radius: 5px !important;
  padding: 5px 30px !important;
  color: #07003B !important;
  transition: all 0.3s ease !important;
}

.tabSection button {
  text-align: left !important;
  -webkit-box-align: start;
  align-items: flex-start;
  background-color: #E6E6E6 !important;
  border-radius: 0px !important;
  color: #4E5360 !important;
  margin: 10px 0 !important;
  text-align: left !important;
  display: flex !important;
  align-items: left !important;
  -webkit-align-items: left !important;
  max-width: 100%;
  min-width: 100%;
}

.tabSection .css-7ozjwe {
  display: none;
}

.css-1vr8b6v {
  border: none;
}

.tabSection button.Mui-selected {
  background-color: #009EE0 !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.mainCol {
  flex-wrap: wrap;
}

.col_M {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E6E6E6;
  padding: 10px;
}

@media screen and (max-width: 767px) {

  .radiologyListing .css-19owhuc {
    flex-wrap: wrap !important;
  }

  .tabSection {
    width: 100% !important;
  }

  .radiologyListing .css-35zqo5-MuiTabs-root {
    width: 100% !important;
  }

  .radiologyListing .tabPannel {
    width: 100% !important;
  }

  .radiologyListing .css-19kzrtu {
    padding: 0px !important;
  }

  .radiologyListing .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    margin: 5px 0 !important;
  }

  .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
    height: 25px !important;
    width: 25px !important;
  }

  .categoryListt {
    padding: 5px !important;
  }

  .bookNowtext {
    font-size: 12px !important;
    padding: 5px 20px !important;
  }
}