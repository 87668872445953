.supportCenter {
  margin-bottom: 80px;
}

.supportCenter h3 {
  padding: 10px 0;
}

.supportCenter p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

.container {
  margin: 0 auto;
}

.blogList {
  list-style-type: disc;
  margin: 0;
}

.blogList li {
  padding: 5px 0;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .supportCenter .container {
    flex-direction: column;
  }

  .supportCenter p {
    padding: 0;
  }

  .supportCenter .container img {
    margin-top: 50px;
  }
}