.bookSurgeryS {
  background-image: url(../../../../public/images/bg-tes.png);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bookSurgery .mainTitle span {
  color: #FF9710;
  font-weight: 500;
}

.bookSurgery .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.bookSurgery .mainTitle h2 {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  margin: 5px 0 20px 0;
}

.bookSurgery_span {
  color: #FF9710;
  font-size: 18px;
}

.bookSurgery_title {
  color: #07003B;
  font-weight: 600;
}

.bookSurgery_img {
  width: 70%;
  margin: 0 auto;
}

.bookSurgery_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.submitBtn {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 5px 20px !important;
  width: 35% !important;
  color: #fff !important;
  transition: all 0.3s ease-in-out !important;
}

.submitBtn:hover {
  background-color: #fff !important;
  color: #009EE0 !important;
}


@media only screen and (max-width: 1280px) and (min-width: 998px) {
  .submitBtn {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  .submitBtn {
    width: 70% !important;
    margin: 0 auto 20px auto !important;
  }

  .bookSurgery .mainTitle span {
    font-size: 12px;
  }

  .bookSurgery .mainTitle h2 {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }

  .bookSurgery .css-ovnx7g> :not(style)+ :not(style) {
    margin: 0px !important;
  }
}