.bookAppointment {
  background-image: url(../../../../public/images/bookBanner.png);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.btnBookAppointment {
  background-color: #009EE0;
  border: 1px solid #009EE0;
  border-radius: 0px 15px 15px 15px;
  padding: 10px 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.btnBookAppointment:hover {
  background-color: transparent;
  color: #009EE0;
}

.bookAppointment h1 {
  font-weight: 600;
  font-size: 25px;
}

.bookAppointment .row {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
}

.supportSe {
  background-color: #F0F4F8;
}

@media screen and (max-width: 767px) {
  .bookAppointment {
    height: 100px !important;
  }

  .bookAppointment .col-md-6 {
    flex: 0 0 auto;
    width: 60%;
  }

  .bookAppointment h1 {
    font-weight: 500 !important;
    font-size: 12px !important;
  }

  .bookAppointment .mt-5 {
    margin-top: 1rem !important;
  }

  .btnBookAppointment {
    padding: 5px 10px !important;
    font-size: 10px !important;
  }

  .appointment {
    padding: 50px 0 0 0 !important;
  }

  .submitBtn {
    font-size: 14px !important;
  }
}