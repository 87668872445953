footer {
  padding: 140px 0 20px 0px;
  background-image: url(../../../../public/images/footer_bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

footer p {
  color: #fff;
  padding: 5px 0;
}

footer p a {
  color: #fff;
  text-transform: lowercase;
}

footer p a:hover {
  color: #ff9710;
}

footer h3 {
  color: #fff;
  font-size: 20px;
}

footer ul {
  padding: 0px;
}

footer ul li {
  padding: 5px 0;
  color: #fff;
}

footer ul li a {
  color: #fff;
  font-size: 13px;
}

footer ul li a:hover {
  color: #ff9710;
}

.copyright {
  margin: 20px 0 0 0;
  background-color: #009ee0;
  box-shadow: 0 0 20px 0 rgb(70 70 70 / 18%);
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: right;
  border-radius: 10px;
}

.linkFooter {
  width: 40%;
  text-align: end;
}

.linkFooter a {
  color: #fff;
  text-transform: capitalize;
  font-size: 13px;
}

.logoFooter a {
  color: #fff;
  font-size: 14px;
  margin: 0 10px;
}

.linkFooter img {
  width: 40px !important;
  margin: 0 20px 0 0;
  border-radius: 5px;
}

.whats {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #f4f4f4;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whats i {
  color: #25d366;
  font-size: 25px;
}

.callB {
  position: fixed;
  right: 15px;
  bottom: 70px;
  z-index: 99999;
  background: #f4f4f4;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whats i {
  color: #25d366;
  font-size: 25px;
}

@media screen and (max-width: 767px) {
  footer {
    padding: 230px 0 40px 0px !important;
  }

  .copyright {
    flex-wrap: wrap !important;
  }

  footer ul li {
    padding: 0px;
  }

  .linkFooter a,
  footer ul li a,
  footer p {
    font-size: 10px !important;
  }

  footer h3 {
    font-size: 14px;
  }

  .addr {
    display: flex !important;
    flex-wrap: nowrap !important;
  }

  .addr .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .addr .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}