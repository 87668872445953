.privacyPolicy {
  margin-bottom: 80px;
}

.privacyPolicy .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.privacyPolicy p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

.container {
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .privacyPolicy .container {
    flex-direction: column;
  }

  .privacyPolicy p {
    padding: 0;
  }

  .privacyPolicy .container img {
    margin-top: 50px;
  }

  .supportCenter .heading,
  .termsConditions .heading,
  .privacyPolicy .heading {
    margin: 0px !important;
  }

  .heading h1 {
    font-size: 14px;
  }
}
