.OurServices .mainTitle {
  text-align: center;
}

.OurServices .mainTitle span {
  color: #ff9710;
  font-weight: 500;
}

.OurServices .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.OurServices .mainTitle h2 {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  margin: 5px 0 20px 0;
}

.OurServices .box {
  box-shadow: 0 4px 4px rgb(153 153 153 / 39%);
  border-radius: 40px 10px;
  text-align: center;
  padding: 20px;
  /* cursor: pointer; */
  position: relative;
  height: 100%;
}

.OurServices .box :hover:after {
  opacity: 1;
  width: 30px;
  height: 30px;
}

.OurServices .box :after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  top: 0px;
  right: 0px;
  z-index: 1;
  border-radius: 0px 10px 0px 45px;
  opacity: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  background: #009ee03d;
}

.OurServices img {
  width: 65px;
  height: 65px;
  margin: auto;
}

.OurServices .content {
  padding: 10px;
  width: 20%;
}

.OurServices .content h4 {
  margin: 0;
  padding: 10px 0 0 0;
  color: #07003b;
  font-size: 16px;
  line-height: 1.35;
}

.OurServices .content p {
  margin: 0;
  padding: 10px 0 0 0;
  color: #4e5360;
  font-size: 12px;
  line-height: 1.35;
}

.readMore {
  color: #009ee0;
  font-size: 14px;
}

.readMore svg {
  width: 16px;
}

.OurServices button {
  background: #009ee0;
}

@media screen and (max-width: 767px) {
  .OurServices .content {
    width: 100%;
  }

  .OurServices .content h4,
  .OurServices .mainTitle span {
    font-size: 12px;
  }

  .OurServices .mainTitle h2 {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }
}
