.signBtn {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 10px 20px !important;
  margin: 20px 0 0 0px !important;
  color: #fff !important;
}

.paymentTitle {
  color: #4E5360;
  font-size: 18px;
  margin: 0px !important;
}

.paymentTitle span {
  color: #FF9710;
}

.totalPaySec {
  background-color: #FCFCFC;
  padding: 10px;
}

.totalPaySec h4 {
  color: #4E5360;
  font-size: 22px;
}

.totalPay {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalPay h6 {
  color: #4E5360;
  font-size: 14px;
}

.totalPay h5 {
  color: #FF9710;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .formBg .css-e53awj-MuiStack-root {
    flex-wrap: wrap !important;
  }

  .formBg .css-1sfvfow-MuiStack-root {
    width: 100% !important;
  }

  .css-e53awj-MuiStack-root>:not(style)+:not(style) {
    margin: 16px 0 0 0 !important;
  }

  .css-6shrky {
    width: 100% !important;
  }

  .css-ikzlcq {
    margin: 0px !important;
    width: 100% !important;
  }

  .css-1t62lt9 {
    flex-wrap: wrap !important;
    flex-direction: column-reverse !important;
  }

  .css-1t62lt9> :not(style)+ :not(style) {
    margin: 6px 0 !important;
  }

  .css-6shrky> :not(style)+ :not(style) {
    margin: 0px !important;
  }

  .css-feqhe6 {
    margin: 6px 0 !important;
  }
}