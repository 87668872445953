.faq {
  margin-bottom: 80px;
}

.faq .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.faq p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

.container {
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .faq .container {
    flex-direction: column;
  }

  .faq p {
    padding: 0;
  }

  .faq .container img {
    margin-top: 50px;
  }
}