.bloodBankFilterItemContent strong {
  color: #1f3e72;
  font-size: 16px;
  font-weight: 500;
}

.bloodBankFilterItemContentItem {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.bloodBankFilterItemContentItem input {
  margin: 0 10px 0 0;
}

.bloodBankFilterBtn {
  background-color: #f5f5f5;
  color: #4e5360;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bloodBankFilterBtn img {
  width: 20px;
}

.bloodBankItemContent {
  box-shadow: 0px 0px 80px #0000000d;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.bloodBankItemContent h3 {
  font-size: 18px;
  font-weight: 500;
  color: #1f3e72;
  margin: 0 0 5px 0;
}

.bloodTi {
  display: flex;
  align-items: center;
}

.bloodTi img {
  width: 25px;
  margin: 0px 10px 0 0;
}

.bloodBankItemContent th,
td,
tr {
  padding: 5px !important;
  border-bottom: 1px solid #f4f4f4 !important;
}

.bloodBankItemContentBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0 0;
}

.footerBank {
  width: 70%;
}

.bloodU {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bloodU span {
  color: #ff9710;
}

.bloodBank .bookNowBtn {
  padding: 5px 10px;
  font-size: 14px;
}

.bloodBankItem {
  width: 30%;
  margin: 0 0 0 -80px;
}

.contentBlood {
  width: 80%;
  margin: 0 0 0 20px;
}

.contentBlood h3 {
  text-transform: capitalize;
}

.bloodSp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.facilityI {
  justify-content: end;
}

.facilityI p {
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.facilityI p img {
  height: 20px;
  width: 20px;
  margin: 0 5px 0 0;
}

@media screen and (max-width: 767px) {

  .bloodBankItemContentBtn,
  .bloodBankItemContent {
    display: block;
  }

  .bloodBankItem {
    width: 100%;
    margin: 10px 0;
  }

  .contentBlood,
  .footerBank {
    width: 100%;
    margin: 0;
  }

  .bloodU,
  .bookNowBtn {
    margin: 10px 0;
  }

  .filterBlo {
    height: 200px;
    overflow: scroll;
  }

  .bloodBank .bookNowBtn,
  .bloodBankItemContent th,
  td,
  tr,
  .bloodBankItemContent h3 {
    font-size: 12px;
  }
}