.css-1fjvggn-MuiPaper-root-MuiAccordion-root {
  margin: 10px 0;
  border: none !important;
  box-shadow: 0px 0px 90px #0000000a !important;
  border-radius: 10px !important;
  overflow: hidden;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  display: none !important;
}

.packageAcc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.packageAcc h3 {
  color: #07003B;
  font-size: 16px;
  margin: 0px;
}

.packageAcc h4 {
  color: #4E5360 !important;
  font-size: 16px;
}

.packageAcc h4 span {
  color: #FF9710;
}

.bloodAnalysis h3 {
  color: #1F3E72;
  font-size: 14px;
  padding: 10px 0 0 0;
}

.textDetailList {
  display: flex;
  align-items: center;
}

.packageDe {
  display: flex;
  align-items: center;
  padding: 10px 10px;
}

.iconSec {
  width: 25px;
  height: 25px;
  background-color: #F5F5F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconSec svg {
  color: #ddd;
  width: 16px;
}

.packageDe p {
  color: #707E95 !important;
  font-size: 14px !important;
  padding-left: 10px;
}

.packageDe p span {
  color: #009B48;
}

.addBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0 0;
}

.addressBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  border-radius: 10px !important;
}

.css-ta84vm-MuiAccordionDetails-root {
  border: none !important;
}

.widthAccor {
  width: 50%;
  padding: 10px 0;
}

.miniAccordian h3 {
  color: #4E5360;
  font-size: 12px !important;
}

.widthAccor p {
  padding: 0 0 10px 0;
}

.testDes {
  font-size: 12px;
}

.changeChildPak {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: none;
  border-radius: 16px;
  padding: 40px 24px 24px 24px;
  text-align: center;
}

.modalpa .css-7pksub {
  box-shadow: none !important;
}

.css-919eu4,
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop,
.modalpa .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.pckCard {
  border: none !important;
  border-radius: 10px !important;
  margin: 10px 0 !important;
  overflow: hidden !important;
}

.css-1fpbxjw {
  border-top: none !important;
}

.mrpDesign {
  text-decoration: line-through;
  color: #ff971070;
}

.ForwardRef\(TextField\)-root-1 label.Mui-focused {
  color: #000 !important;
}

@media screen and (max-width: 767px) {
  .packageAcc {
    flex-wrap: wrap;
  }

  .packageAcc .text-end {
    text-align: left !important;
  }

  .packageAcc h3 {
    width: 100% !important;
    font-size: 14px;
  }

  .mrpDesign,
  .packageAcc h4 {
    font-size: 12px;
  }
}