.labsListing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.labsListing h3 {
  color: #07003B !important;
  font-size: 18px !important;
  margin: 0px !important;
  font-weight: 600;
}

.labsListing h4 {
  color: #4E5360 !important;
  font-size: 18px !important;
  margin: 0px !important;
}

.labsListing h4 span {
  color: #FF9710 !important;
}

.labsListCol {
  padding: 7px 0;
  display: flex;
  align-items: center;
}

.iconLabs {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconLabs svg {
  width: 16px;
}

.labsListCol p {
  color: #707E95;
}

.labsListCol p span {
  color: #009B48;
}

@media screen and (max-width: 767px) {
  .bookRadiologyTests .css-mhc70k-MuiGrid-root {
    flex-wrap: wrap !important;
  }

  .bookRadiologyTests .css-1osj8n2-MuiGrid-root {
    -webkit-flex-basis: 100% !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  .labsListing h4,
  .labsListing h3 {
    font-size: 14px !important;
  }
}