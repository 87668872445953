.termsConditions {
  margin-bottom: 80px;
}

.termsConditions .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.termsConditions p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

.container {
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .termsConditions .container {
    flex-direction: column;
  }

  .termsConditions p {
    padding: 0;
  }

  .termsConditions .container img {
    margin-top: 50px;
  }
}
