/* .clinic .back,
.clinic .back img,
.clinic .back::after {
  height: 50vh !important;
} */
.contactL {
  color: #343e6a;
  font-weight: 600 !important;
  text-transform: capitalize;
  font-size: 30px !important;
  text-align: center;
  padding: 20px 0;
}

.cliniCard {
  box-shadow: 0px 0px 80px #0000000d;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin: 15px 2px;
}

.cliniCardImg {
  width: 300px;
  height: 250px;
  margin: 0 auto;
  border-radius: 10px 10px 0 0;
}

.cliniCardImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cliniCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cliniCardTitle h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1f3e72;
  margin: 0;
}

.cliniCardTitle h3 span {
  color: #ff9710;
}

.cliniCardContent p {
  font-size: 14px;
  font-weight: 400;
  color: #707e95;
  margin: 0;
  text-transform: capitalize;
}

.clinicD {
  padding: 15px 0;
}

.clinicDItem {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.clinicDItem p {
  font-size: 14px;
  font-weight: 400;
  color: #1f3e72;
  margin: 0;
}

.clinicDicon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clinicDicon svg {
  width: 18px;
  color: #09cf65;
}

.viewBook {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clinicSearch {
  position: absolute;
  top: 230px;
  width: 100%;
}

.searchBox {
  width: 30%;
}

.searchBox .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  background-color: #fff;
  border-radius: 5px;
}

.searchBox .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  background-color: #009ee0;
  border-radius: 5px !important;
  color: #fff !important;
}

@media screen and (max-width: 767px) {
  .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
    height: 25px !important;
    min-width: 25px !important;
  }

  .searchBox {
    width: 100% !important;
  }

  .clinic .back,
  .clinic .back img,
  .clinic .back::after {
    height: 40vh !important;
  }

  .clinicSearch {
    top: 160px !important;
  }

  .cliniCardImg {
    width: 100% !important;
  }

  .clinicDItem p {
    width: 90% !important;
  }
}