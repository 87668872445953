.changePBtn {
    border-radius: 5px !important;
    padding: 5px 20px !important;
    background: #e0752f !important;
    border: none !important;
    color: #fff !important;
    cursor: pointer !important;
    font-weight: bold !important;
    margin: 10px 0 0 0px !important;
}

@media screen and (max-width: 768px) {}