.labDetailPage__content h2 {
  color: #1F3E72;
  font-size: 24px;
}

.labDetailPage__sidebar h4 {
  color: #1F3E72;
  font-size: 16px;
  padding: 0 0 10px 0;
}

.labDetailPage__sidebar h4 span {
  color: #FF9710;
}

.book_rightSec {
  width: 50%;
  background-color: #FFF5D5;
  margin: 0 10px 0 0;
  height: 180px;
  position: relative;
}

.book_rightSec img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.radiologyDetailPage .box {
  padding: 20px;
  cursor: pointer;
  display: flex;
}

.radiologyDetailPage .content {
  padding: 10px;
}

.radiologyDetailPage .react-multiple-carousel__arrow {
  display: none;
}

.radiologyDetailPage .slideText {
  width: 50%;
}

.radiologyDetailPage .slideText h4 {
  color: #07003B;
  font-size: 18px;
}

.radiologyDetailPage .slideText h5 {
  color: #4E5360;
  font-size: 16px;
}

.radiologyDetailPage .slideText h6 {
  color: #4E5360;
  font-size: 14px;
}

.radiologyDetailPage .bookingfooter {
  background-color: #FFD016;
  padding: 5px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.radiologyDetailPage .bookingfooter p {
  color: #fff;
}

@media screen and (max-width: 767px) {
  .labDetailPage__content h2 {
    font-size: 14px;
  }

  .labDetailPage__sidebar h4 {
    font-size: 12px;
    padding: 15px 0 0px 0;
  }
}