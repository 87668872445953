/* .stepSec .mainTitle {
  text-align: center;
}

.stepSec .mainTitle span {
  color: #FF9710;
  font-weight: 500;
}

.stepSec .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.stepSec .mainTitle h2 {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  margin: 5px 0 20px 0;
}

.title_h {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  text-align: center;
  top: 0;
  position: relative;
}

.title_h span {
  display: block;
  text-align: center;
  height: 6px;
  margin: 0 auto;
  background: #C1E6FF;
  border-radius: 8px;
  width: 150px;
  position: relative;
  top: 10px;
}

.process .arrow-col {
  position: relative;
  height: 70px;
  text-align: center;
  padding-top: 15px;
}

.process .circle-icon-container {
  width: 110px;
  height: 110px;
  text-align: center;
  padding: 25px;
  margin: 0 auto 10px auto;
  background: #dddddd4d;
  border-radius: 50%;
}

.process .circle-icon-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hhhhh {
  font-weight: bold;
  color: #00214a;
  font-size: 14px;
}

.ppppp {
  color: #0e1d34;
  font-size: 12px;
}

.process .content-col {
  flex-grow: 3;
}

.wrapper img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  text-align: center;
  transition: transform .7s ease-in-out;
}

.wrapper:hover img {
  transform: rotate(360deg);
} */

.stepSec {
  position: relative;
  padding: 60px 0;
  background-color: #F0F4F8;
  overflow: hidden;
}

.stepSec .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.grafh {
  width: 100%;
  display: flex;
  padding-top: 43px;
  justify-content: space-between;
  margin: auto;
}

.svg-box {
  position: relative;
  /* margin: 0px 53px 0px 6px; */
}

.svg-box:before {
  position: absolute;
  width: 50px;
  height: 38px;
  background-image: url('../../../../public/images/arrow.png');
  content: '';
  right: -75px;
  background-repeat: no-repeat;
  background-size: cover;
  top: 76px;
}

.bg-icon2:before {
  content: '';
  background-image: none;
}

.circle-box:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  background: #FF9710;
  animation: rotate 2s linear reverse infinite;
  border-radius: 50px;
}

.dark-red:before {
  background-color: #48102b;
}

.blue:before {
  background-color: #009EE0;
}

.firstBB:before {
  background-color: #00f6ff;
}

.circle-logo {
  width: 155px;
  height: 155px;
  background-color: #ffffff;
  border-radius: 100px;
  /* opacity: 0; */
  animation: fadeIn 0.75s linear forwards;
  animation-delay: 1s;
  box-shadow: 0px 1px 12px #c1b8b8;
  position: absolute;
  top: 23px;
  left: 23px;
}

.circle-logo img {
  height: 74px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: auto;
  margin-top: 11px;
}

.circle-logo p {
  font-size: 14px !important;
  text-align: center;
  line-height: 16px !important;
  padding: 10px 11px;
}

.circleB {
  width: 10px;
  height: 10px;
  background: cyan;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circleB 4s linear infinite;
}

@keyframes circleB {
  0% {
    transform: rotate(0deg) translate(-95px) rotate(0deg);

  }

  100% {
    transform: rotate(360deg) translate(-95px) rotate(-360deg);
  }
}


@media screen and (max-width: 767px) {
  .grafh {
    flex-wrap: wrap;
    justify-content: center;
  }

  .svg-box:before {
    display: none;
  }

  .svg-box {
    margin: 0px;
  }

  .stepSec .mainTitle span {
    font-size: 12px;
  }

  .stepSec .mainTitle h2 {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }

  .circle-logo p {
    font-size: 11px !important;
  }
}