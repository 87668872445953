.contact form div {
  display: flex;
}

.contact textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px;
}

.contact input {
  margin-bottom: 20px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px;
}

.contact form {
  padding: 30px;
}

.contactPage {
  /* margin: -80px auto 20px; */
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 10px rgb(179 179 179 / 80%), 0 0 1px 1px #ededed;
  font-size: 1.285rem;
  color: #4c5769;
  overflow: hidden;
}

.contact-con {
  background: var(--orange-bg);
  text-align: center;
  padding: 20px 0;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
}

.contact-con .col {
  width: 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
}

.contact-con .col+.col {
  border-left: 1px solid #f79d51;
}

.aside {
  text-align: center;
  padding: 20px 0;
  color: #4c5769;
  margin: 20px 0;
  border: 1px solid #dadada;
}

.h4 {
  color: #000;
  font-weight: bold;
  font-size: 22px;
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}

.contact-con figure {
  padding: 10px;
  margin: 0;
}

.contact-con figure img {
  max-height: 280px;
  max-width: 200px;
}

.contact-con .h3 {
  color: #000;
  font-weight: bold;
  font-size: 24px;
}

.contact-con p {
  font-weight: 300;
  margin-bottom: 10px;
}

.lead {
  font-size: 21px;
}

.contact-con a {
  color: #4c5769;
  font-weight: bold;
  font-size: 20px;
  text-transform: lowercase;
}

.listCheckOffer li {
  list-style-type: disc;
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  .contact h4 {
    width: 100%;
    margin-bottom: 30px;
  }

  .contact form div {
    flex-direction: column;
  }

  .contact input {
    width: 100%;
  }

  .back .container {
    padding: 90px 10px 10px 10px;
  }

  .back h1 {
    font-size: 14px;
  }

  .back span {
    font-size: 12px;
  }

  .contact-con .flex {
    flex-wrap: wrap;
  }

  .contact-con .col {
    width: 100%;
    max-width: 100%;
    display: block;
    flex: auto;
  }

  .contact-con a,
  .lead,
  .contact-con .h3 {
    font-size: 14px;
  }
}