.findClinic .mainTitle span {
  color: #FF9710;
  font-weight: 500;
}

.findClinic .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.findClinic .mainTitle h2 {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  margin: 5px 0 20px 0;
}

.findSection_span {
  color: #FF9710;
  font-size: 18px;
}

.findSection_title {
  color: #07003B;
  font-weight: 600;
}

.findSection_p {
  color: #4E5360;
}

.findUl li {
  list-style-type: disclosure-closed;
  padding: 5px 0;
}

.btnFindMore {
  background-color: #009EE0;
  border: 1px solid #009EE0;
  border-radius: 0px 15px 15px 15px;
  padding: 10px 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.btnFindMore:hover {
  background-color: transparent;
  color: #009EE0;
}

.findSection_img {
  width: 70%;
  margin: 0 auto;
}

.findSection_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 767px) {

  .findUl li,
  .findClinic .mainTitle span {
    font-size: 12px;
  }

  .findClinic .mainTitle h2 {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }

  .btnFindMore {
    font-size: 12px !important;
  }
}