@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif !important;
  background-color: #fff;
}

p {
  margin-bottom: 0px !important;
  font-size: 13px !important;
  line-height: 1.5 !important;
}

.container {
  max-width: 1250px !important;
  width: 100% !important;
  margin: 0 auto !important;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  color: #07003B;
}

a {
  text-decoration: none !important;
  color: #000;
  text-transform: capitalize;
}

ul {
  list-style-type: none;
}

button {
  border-radius: 25px !important;
  padding: 5px 20px;
  background: #e0752f;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

button i {
  margin-right: 5px;
}

.pageTitle {
  color: #343e6a;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 30px;
}

.pageTitle span {
  color: #FF9710;
}

.heading h1 {
  color: #343e6a;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 28px;
}

.heading p {
  color: #72809d;
}

textarea,
input {
  border: none;
  outline: none;
  background: none;
}

.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

label {
  color: #2d3954;
  font-size: 14px;
}

.mtop {
  margin-top: 50px;
}

h4 {
  font-weight: 500;
  margin-top: 10px;
}

.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}

.padding {
  padding: 80px 0;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.btn2 {
  border-radius: 50px;
  font-size: 20px;
}

.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.btn3 {
  background: #ff6922;
  border-radius: 50px;
}

.btn4 {
  background-color: #000;
  color: #fff;
}

.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: #27ae60;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #27ae601f;
  background: #fff;
}

.back {
  /* height: 40vh; */
  position: relative;
  border-bottom: 1px solid #f4f4f4;
}

.back img {
  /* height: 40vh; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E8F1FF;
  z-index: -1;
  opacity: 0.9;
}

.back span {
  color: #4E5360;
}

.back .container {
  color: #fff;
  padding: 120px 0;
}

.back h1 {
  font-size: 40px;
  font-weight: 500;
  color: #4E5360;
}

.mb {
  margin-bottom: 80px;
}


.upload__image {
  border: 1px solid;
  border-radius: 5px;
  border-color: rgba(145, 158, 171, 0.32);
  padding: 0px 5px;
}

.dFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 5px 0;
}

.imgSec {
  width: 20%;
  height: 50px;
  margin: 0 5px 0 0;
}

.dFlex img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photobtn {
  background-color: #05C887;
  color: #fff;
  box-shadow: 0 8px 16px 0 rgb(32 101 209 / 24%);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 3px 5px;
  border-radius: 8px;
  font-weight: 600;
  line-height: 1.7142857142857142;
  font-size: 0.7rem;
  border: 0;
  margin: 5px 0 5px 0;
}

.dFlex input {
  width: 60%;
}

.dFlex .photobtn {
  width: 20%;
}

.react-multiple-carousel__arrow {
  min-width: 30px !important;
  min-height: 30px !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 12px !important;
}

@media screen and (max-width: 767px) {

  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading {
    width: 100%;
  }

  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}

/* DateTimePicker calender fix */

[role="dialog"].MuiPickersPopper-root.MuiPopperUnstyled-root {
  z-index: 99999;
}