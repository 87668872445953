.compareHospitalList {
  display: flex;
  align-items: center;
  justify-content: center;
}

.compareHospitalList p {
  color: #707E95;
  font-size: 16px !important;
  padding: 0 5px;
}

.removeBtn {
  background-color: #FCEEEE;
  color: #CB0519;
  border-radius: 10px !important;
  padding: 5px 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px auto;
}

.removeBtn svg {
  width: 18px;
}

.hospitalName {
  border-right: 1px solid #ddd;
  padding: 0 10px;
}

.hospitalName:last-child {
  border-right: none;
}

.comp {
  border: 1px solid #ddd !important;
  padding: 5px 10px;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .compareHospitalList {
    flex-wrap: wrap !important;
  }

  .compareHospitalList p {
    width: 100% !important;
  }
}