::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF9710;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF9710;
}

header {
  border: 0;
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  padding: 20px 0px 10px 0px;
  background: #E8F1FF;
}

header img {
  width: 170px;
}

header a {
  padding: 0 10px;
  transition: 0.5s;
  font-weight: 500;
  color: #0b0b05;
  font-size: 12px;
  text-transform: uppercase;
  border-right: 1px solid #4e53605e;
}

header a:hover {
  color: #ff9710;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1iwjfd1 .MuiOutlinedInput-root,
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 35px !important;
  padding-left: 0 !important;
}

.css-edpqz1,
.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  margin-right: -18px !important;
}

.css-uge3vf,
.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  margin-right: -10px !important;
}

header span {
  width: auto;
  padding: 0px 6px;
  background: transparent;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}

header h4 {
  font-weight: 500;
  margin-right: 20px;
}

header .toggle {
  display: none;
}

.logo a {
  border-right: none;
}

.nav ul {
  margin: 0px;
  padding: 0px;
}

.nav .bbb a {
  border-right: none;
}

.btnJoinNow {
  background-color: #ff9710;
  border: 1px solid #ff9710;
  border-radius: 0px 15px 15px 15px;
  padding: 8px 6px;
  color: #fff;
}

.btnJoinNow:hover {
  background-color: transparent;
  color: #ff9710;
}

.nav img {
  width: 15px !important;
  margin: 0 5px 0 0;
}

.myProfileBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_sec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_sec img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

.myAccountList svg {
  width: 18px;
  margin: 0 10px 0 0;
}

.myAccountList a {
  color: #4e5360;
  font-size: 13px;
  font-weight: 500;
  display: block;
  transition: 0.5s;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 10px !important;
  overflow: hidden;
}

.megamenu .menuList {
  position: absolute;
  /* display: none; */
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(70 70 70 / 18%);
  margin: 0;
  border-radius: 15px;
  border-bottom: 2px solid #ff9710;
  width: 96%;
  left: 2%;
  top: 52px;
  overflow: hidden;
  z-index: 9;
}

.dNone {
  display: none;
}

.dBlock {
  display: block;
}

/* .megamenu:hover .menuList {
  display: block;
} */

.menuList h4 {
  font-size: 12px;
  color: #009ee0;
  padding: 10px 0;
  margin: 0px;
  text-transform: uppercase;
}

.menuList ul li {
  list-style-type: disc;
  color: #4e5360;
}

.menuList ul li a {
  padding: 0px;
  margin: 0px;
  font-size: 11px;
  border-right: none;
  text-transform: capitalize;
}

.menuList .menuItem {
  column-count: 6;
  justify-content: space-between;
  height: 100%;
}

.menuItemList {
  display: inline-block;
  padding: 0 8px;
  box-sizing: border-box;
  margin-bottom: 16px;
  width: 100%;
}

.menuItemList ul {
  margin: 0 0 0 20px;
}

.menu {
  position: relative;
}

.menu ul {
  position: absolute;
  display: none;
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(70 70 70 / 18%);
  border-radius: 0px 15px 15px 15px;
  margin: 0;
  width: 200px;
  left: 0;
  top: 25px;
  padding: 5px 0px 10px 30px;
  overflow: hidden;
  border-bottom: 2px solid #ff9710;
}

.menu ul li {
  padding: 5px 0;
  list-style-type: disc;
  color: #00214a;
}

.menu ul li a {
  padding: 0px;
  font-size: 11px;
  margin: 0px;
  color: #0b0b05;
  border-right: none !important;
  text-transform: capitalize;
}

.menu:hover ul {
  display: block;
}

.locationSele {
  width: 210px;
  height: 40px;
}

#locationwrapper {
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 25px;
}

.loIocon {
  width: 10%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loIocon svg {
  width: 40px;
  height: 40px;
  color: #FF9710 !important;
}

.autoCompl {
  width: 90%;
  padding: 0;
  margin: 0;
}

.autoCompl label {
  background-color: #E8F1FF !important;
  transform: translate(6px, -8px) scale(0.75);
  color: #000 !important;
  z-index: 9999999;
}

.css-ue1yok .MuiAutocomplete-option,
.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 10px !important;
  min-height: 30px !important;
  padding: 5px !important;
}

#MobileMen {
  display: none;
}


/* ------------------------------------------------------ */
.homeModal .submitBtn {
  width: 70% !important;
  margin: 0 auto;
}

.modalHe {
  background-color: #f0f4f8;
}

.modalClose {
  background-color: #f0f4f8 !important;
  position: absolute !important;
  right: -10px !important;
  top: -10px !important;
  padding: 10px 12px !important;
  min-width: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modalClose:hover {
  background-color: #f0f4f8 !important;
}

.modalClose i {
  margin: 0px;
}

@media only screen and (max-width: 1320px) and (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 998px) {
  header a {
    font-size: 10px !important;
  }

  .locationSele {
    width: 200px;
  }
}

@media screen and (max-width: 767px) {

  .modalMain {
    width: 290px !important;
  }

  .modalHe {
    font-size: 14px !important;
  }

  .homeModal .submitBtn {
    width: 100% !important;
  }

  .menuList .menuItem {
    column-count: 2;
  }

  .locationSele .css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: -10px;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1iwjfd1 .MuiOutlinedInput-root,
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 10px !important;
    padding-left: 0 !important;
  }

  header a {
    padding: 0 12px 0 0px !important;
    border-right: none;
  }

  .locationSele {
    padding: 5px 0 0 0;
    width: 150px;
  }

  .locationSele .css-edpqz1 {
    display: none !important;
  }

  .locationSele .css-1iwjfd1 .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 120px !important;
  }

  .locationSele .css-1iwjfd1 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: -7px !important;
  }

  .top-header {
    display: none;
  }

  .container {
    max-width: 100% !important;
    padding: 0 5px;
  }

  header {
    padding: 5px 0 0 0;
  }

  header a {
    padding: 0 5px 0 0 !important;
  }

  header .nav .flex {
    display: none;
  }

  header .toggle {
    display: block;
    margin: 6px 0 0 10px;
  }

  header .small {
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: #27ae60;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 20px 0 rgb(70 70 70 / 18%);
  }

  .bannerContent h1 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .bannerContent p {
    font-size: 10px !important;
  }

  .myProfileBtn {
    display: block;
  }

  header li a {
    display: inline-block;
    margin-bottom: 15px;
    margin-left: 0px;
    color: #fff;
  }

  .menu ul li a,
  .menuList ul li a {
    color: #00214a;
  }

  .megamenu .menuList,
  .menu ul {
    z-index: 9 !important;
    width: 100%;
  }

  #mobileHeaderC .menu ul {
    width: 150px;
  }

  .menuList h4 {
    font-size: 10px;
    padding: 5px 0;
  }

  .nav ul {
    padding: 15px 10px 10px 15px !important;
  }

  .btnJoinNow {
    padding: 8px 5px !important;
  }

  button {
    padding: 5px 10px !important;
  }

  button i {
    margin-right: 0px;
  }

  header h4 {
    font-size: 12px;
  }

  .menuList .col-2 {
    width: 100% !important;
  }

  .menuList ul li a {
    font-size: 10px;
  }

  .megamenu .menuList {
    padding: 10px 5px;
    top: 25px;
  }

  .menuItemList {
    margin-bottom: 5px;
  }

  header img {
    width: 95px;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1iwjfd1 .MuiOutlinedInput-root input,
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root input {
    font-size: 12px;
  }

  .autoCompl label {
    font-size: 12px;
    transform: translate(5px, -4px) scale(0.75);
  }

  p {
    font-size: 11px !important;
  }

  .menu ul li {
    margin: 0 0 0 10px;
  }

  #locationwrapper {
    height: 30px;
    margin: 5px 0 0 0;
  }

  .css-uge3vf,
  .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
    margin-right: 0px !important;
    padding: 0 10px 0 0 !important;
  }

  .mobileDiNone {
    display: none;
  }

  #mobileHeaderC {
    width: 100%;
    position: absolute;
    top: 50px;
    background: #fff;
    z-index: 9;
    box-shadow: 0 0 20px 0 rgb(70 70 70 / 10%);
    padding: 7px 0;
  }

  #mobileHeaderC ul li a {
    padding: 0 5px;
    transition: 0.5s;
    font-weight: 500;
    color: #009EE0;
    font-size: 12px;
    text-transform: uppercase;
  }

  #mobileHeaderC ul ul li a {
    padding: 0 5px;
    transition: 0.5s;
    font-weight: 500;
    color: #4e5360;
    font-size: 8px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  #mobileHeaderC .nav ul {
    padding: 5px !important;
  }

  #MobileMen {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #mobileHeaderC .megamenu,
  #mobileHeaderC .menu,
  #mobileHeaderC .nnnnn {
    border: 1px solid #009EE0;
    margin: 0 4px;
    border-radius: 5px;
  }

  #mobileHeaderC .menu ul {
    padding: 15px 10px 10px 15px !important;
  }
}

#locationwrapper>div>div>label {
  background-color: #e8f1ff;
}