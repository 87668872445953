.UserReviews .mainTitle span {
  color: #FF9710;
  font-weight: 500;
}

.UserReviews .mainTitle span img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
}

.UserReviews .mainTitle h2 {
  font-size: 25px;
  color: #00214a;
  font-weight: 600;
  margin: 5px 0 20px 0;
}

.aboutSection_span {
  color: #FF9710;
  font-size: 18px;
}

.aboutSection_title {
  color: #07003B;
  font-weight: 600;
}

.aboutSection_p {
  color: #4E5360;
}


.UserReviews .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #009EE0;
  height: 100%;
}

.UserReviews .imageSec img {
  width: 65px;
  height: 65px;
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 50%;
  padding: 5px;
}

.UserReviews .content {
  padding: 10px;
  height: 100%;
}

.imageSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageSec i {
  color: #3069DC;
}

.UserReviews .content p {
  margin: 0;
  padding: 10px 0 0 0;
  color: #434343;
  font-size: 14px;
  line-height: 1.35;
}

.UserReviews .content h4 {
  margin: 0;
  padding: 10px 0 0 0;
  color: #232323;
  font-size: 14px;
  line-height: 1.35;
}

.UserReviews .content span {
  font-size: 10px;
}

.UserReviews .react-multi-carousel-list {
  padding: 0 0 55px 0;
}

.UserReviews button {
  bottom: 0;
  background: #009EE0;
}

.UserReviews .react-multiple-carousel__arrow--left {
  left: calc(42% + 1px);
}

.UserReviews .react-multiple-carousel__arrow--right {
  right: calc(42% + 1px);
}

.testiRtin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.starS i {
  color: #EBBE0B;
}

@media screen and (max-width: 767px) {
  .UserReviews .react-multiple-carousel__arrow--right {
    right: calc(30% + 1px) !important;
  }

  .UserReviews .react-multiple-carousel__arrow--left {
    left: calc(30% + 1px) !important;
  }

  .UserReviews .content h4,
  .UserReviews .mainTitle span {
    font-size: 12px;
  }

  .UserReviews .mainTitle h2 {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }
}