.detailBook h2 {
  color: #4E5360;
  font-size: 18px;
  padding: 0 0 20px 0;
}

.detailBook h2 span {
  color: #FF9710;
  font-size: 18px;
}

.titleClinic {
  color: #1F3E72;
  font-size: 16px;
  padding: 20px 0 0 0;
}

.otpSec2 {
  text-align: center;
}

.titleClinic span {
  color: #FF9710;
  font-size: 16px;
}

.titleFacilities {
  color: #1F3E72;
  font-size: 20px;
  padding: 10px 0 0 0;
  background-color: #f7f6f6;
  font-weight: 600;
}

.facilitiesList h6 {
  color: #1F3E72;
  font-size: 14px;
}

.freeOpd {
  color: #FF9710;
}

.book_rightSec {
  width: 50%;
  background-color: #FFF5D5;
  margin: 0 10px 0 0;
  height: 180px;
  position: relative;
}

.book_rightSec img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.clinicDetailPage .box {
  padding: 20px;
  cursor: pointer;
  display: flex;
}

.clinicDetailPage .content {
  padding: 10px;
}

.clinicDetailPage .react-multiple-carousel__arrow {
  display: none;
}

.slideText {
  width: 50%;
}

.slideText h4 {
  color: #07003B;
  font-size: 18px;
}

.slideText h5 {
  color: #4E5360;
  font-size: 16px;
}

.slideText h6 {
  color: #4E5360;
  font-size: 14px;
}

.bookingfooter {
  background-color: #FFD016;
  padding: 5px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.bookingfooter p {
  color: #fff;
}

.pr {
  padding-right: 10px;
  color: #1F3E72;
  font-weight: 600;
  width: 100px;
}

.availableColor {
  color: #44D7B6;
}

@media screen and (max-width: 767px) {
  .titleFacilities {
    font-size: 12px;
  }
  .facilitiesList h6{
    font-size: 12px;
  }
}