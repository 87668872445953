.orderProcess__content {
  text-align: center;
}

.orderProcess__content img {
  width: 50%;
}

.orderProcess__content p {
  color: #707070;
  font-size: 18px !important;
  padding: 20px 0 0 0;
}

@media screen and (max-width: 768px) {}