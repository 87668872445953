.shortingBtn {
  display: flex;
  align-items: center;
  justify-content: end;
}

.shortingBtn button {
  margin: 0 5px;
  font-weight: 500;
  border-radius: 5px !important;
}

.filterBtn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #FF9710 !important;
  font-size: 16px !important;
  padding: 4px 25px !important;
}

.filterBtn img {
  width: 18px !important;
  height: 16px !important;
  margin-left: 10px !important;
}

.sortBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  color: #4E5360;
}

.sortBtn img {
  width: 18px;
  height: 16px;
  margin-left: 5px;
}

.compareBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #1F3E72;
}

.compareBtn img {
  width: 35px;
  height: 35px;
  margin-left: 5px;
}

.hospitalAndTreatment {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 90px #0000000A;
}

.hospitalImg {
  padding: 0 10px 0 0;
  height: 160px;
  width: 160px;
}

.borderSe {
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hospitalImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.otpSec2 {
  text-align: center;
}

.treatmentTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.compareBtn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #2A4AC8;
  font-weight: 500;
  padding: 0px;
}

.compareBtn2 img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.treatmentTitle h3 {
  color: #07003B;
  font-size: 18px;
}

.treatmentDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.treatmentDetail .treatmentCol {
  width: 20%;
  margin: 5px 0;
}

/* .treatmentDetail .treatmentCol:last-child {
  text-align: end;
} */

.treatmentCol h4 {
  color: #1F3E72;
  font-size: 14px;
  margin: 0px;
  padding: 0 0 5px 0;
}

.treatmentCol h2 {
  color: #4E5360;
  font-size: 18px;
}

.treatmentCol h2 span {
  color: #FF9710;
  font-size: 18px;
}

.bookNowBtn {
  background-color: #009EE0 !important;
  border: 1px solid #009EE0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 5px 20px;
  color: #fff !important;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 14px;
}

.bookNowBtn a {
  color: #fff !important;
}

.bookNowBtn:hover {
  background-color: #fff !important;
  color: #009EE0 !important;
}

.bookNowBtn:hover a {
  color: #009EE0 !important;
}

.drawerrr {
  /* padding: 0 20px; */
  min-width: 300px;
}

.titleF {
  background-color: #FF9710 !important;
  padding: 15px;
  color: #fff;
}

.filter_title {
  position: relative;
  color: #343e6a;
}

.filter_title::after {
  position: absolute;
  content: '';
  top: 15px;
  left: -10px;
  background-color: #009EE0;
  width: 5px;
  height: 5px;
}

.css-3besu,
.css-nnid7-MuiSlider-valueLabel {
  background-color: #FF9710 !important;
}

.css-exkjcx,
.css-187mznn-MuiSlider-root {
  color: #FF9710 !important;
  height: 2px !important;
}

.css-1t2bqnt,
.css-1gv0vcd-MuiSlider-track {
  border: 0px solid currentColor !important;
}

.css-7drnjp,
.css-eg0mwd-MuiSlider-thumb {
  width: 15px !important;
  height: 15px !important;
}

.inputSe {
  width: 100%;
  border-radius: 10px;
}

.inputSe:hover,
.inputSe:hover .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  background-color: #f4f4f4;
  color: #000 !important;
}

.priceSec {
  border: 1px solid #ddd;
  width: 100px;
  margin: 5px 10px 10px 10px;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 13px;
}

@media screen and (max-width: 767px) {

  .treatmentDetail {
    flex-wrap: wrap !important;
  }

  .treatmentCol {
    padding: 5px 0 !important;
    width: auto !important;
  }

  .compareBtn2,
  .treatmentTitle h3 {
    font-size: 12px !important;
    margin: 0px !important;
  }

  .hospitalImg {
    border: none !important;
    width: 100% !important;
  }

  .treatmentCol h2,
  .treatmentCol h2 span,
  .shortingBtn button {
    font-size: 12px !important;
  }

  .filterBtn img {
    width: 9px !important;
    height: 10px !important;
  }

  .css-1wpgmpy-MuiStack-root {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .treatmentCol h4,
  .bookNowBtn {
    font-size: 10px;
  }

  .treatmentDetail .treatmentCol {
    margin: 0px;
  }

  .compareBtn2 {
    text-align: left;
  }

  .MuiDrawer-paper {
    width: 200px !important;
  }

  .filterBtn {
    font-size: 12px;
  }
}

.treatmentRating{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: #1F3E72;
}