.formBg {
  display: block;
  padding: 4em 0;
  background: #fff;
}

.changeBtn {
  /* border-radius: 5px !important;
  padding: 5px 20px !important;
  background: #FD4869 !important;
  border: 1px solid #FD4869 !important;
  color: #fff !important;
  cursor: pointer !important;
  font-weight: bold !important;
  margin: 20px 0 0 0px !important; */
  background-color: #009ee0 !important;
  border: 1px solid #009ee0 !important;
  border-radius: 0px 15px 15px 15px !important;
  padding: 10px 20px !important;
  margin: 20px 0 0 0px !important;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
}
